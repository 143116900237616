<template>
  <Layout :pagetitle="'Principal'">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-12 col-md-6" v-if="currentCategory">
            <div class="card">
              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-1 pb-2 mx-3">
                  <div>
                    <span class="text-muted d-block">Categoria</span>
                    <span :class="`h4 mx-4 text-${company.color ? company.color : 'primary'}`">
                      {{ currentCategoryName }}
                    </span>
                  </div>
                  <i class="mdi mdi-close-thick text-muted h5 mt-3 cursor-pointer" @click="setCurrentCategory(null)"></i>
                </div>
              </div>
            </div>
          </div>
          <div :class="`${currentCategory ? 'col-12 col-md-6' : 'col-12'}`">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-6 d-flex">
                    <div>
                      <eva-icon class="h5 text-muted mx-2 mt-2" name="options-outline"></eva-icon>
                    </div>

                    <span class="skeleton-box mt-2" style="width: 300px" v-if="isLoading"></span>
                    <button
                      v-else
                      type="button"
                      class="btn btn-outline-secondary bg-gradient btn-rounded waves-effect waves-light mx-3"
                      @click="orderByDateDesc()"
                    >
                      <i class="bx bxs-time text-danger"></i>
                      Recentes
                    </button>
                    <button
                      v-if="!isLoading"
                      type="button"
                      class="btn btn-outline-secondary bg-gradient btn-rounded waves-effect waves-light mx-3"
                      @click="orderByHighlight()"
                    >
                      <i class="bx bxs-star text-warning"></i>
                      Destaques
                    </button>
                  </div>
                  <div class="col-sm-6">
                    <form class="mt-4 mt-sm-0 float-sm-end d-sm-flex align-items-center">
                      <div class="search-box me-2">
                        <div class="position-relative">
                          <input
                            type="search"
                            class="form-control border-0 bg-light"
                            placeholder="Buscar..."
                            v-model="filter"
                            @input="filterByTitle(filter)"
                            :disabled="isLoading"
                          />
                          <i
                            class="search-icon"
                            data-eva="search-outline"
                            data-eva-height="26"
                            data-eva-width="26"
                          ></i>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-xl-12">
            <div class="tab-content">
              <div class="tab-pane active" id="product" role="tabpanel">
                <div v-if="isLoading" class="row">
                  <skeleton-card />
                </div>
                <div class="row" v-else>
                  <div v-for="product in productsFiltered" :key="product._id" class="col-xl-6">
                    <div class="card">
                      <div class="card-body">
                        <div class="row align-items-center d-flex justify-content-between mb-2">
                          <div class="col-11">
                            <router-link
                              :to="{
                                name: 'companyProduct',
                                params: {
                                  slug: $route.params.slug,
                                  idProduct: product._id,
                                },
                              }"
                            >
                              <h4 class="mb-2 font-size-20 text-truncate underline-on-hover cursor-pointer">
                                {{ product.title }}
                              </h4>
                            </router-link>
                          </div>
                        </div>
                        <div class="row align-items-center">
                          <div class="col-md-5 text-center">
                            <router-link
                              :to="{
                                name: 'companyProduct',
                                params: {
                                  slug: $route.params.slug,
                                  idProduct: product._id,
                                },
                              }"
                            >
                              <img
                                v-lazy="product.imageUrl"
                                class="rounded product-image cursor-pointer"
                                alt="image-product"
                                style="max-height: 200px"
                                @mouseover="zoomIn"
                                @mouseout="zoomOut"
                              />
                            </router-link>
                          </div>
                          <div class="col-md-7">
                            <div class="mt-3 mt-lg-0">
                              <div :class="`d-flex ${product.highlight ? 'justify-content-between' : 'justify-content-end'}`">
                                <div class="d-flex bg-light rounded px-2" v-if="product.highlight">
                                  <p class="text-muted mb-0">
                                    <i class="bx bxs-star text-warning"></i>
                                    Destaque
                                  </p>
                                </div>

                                <p class="text-muted mb-0">
                                  {{ product.updatedAt ? $utils.formatDateTime(product.updatedAt) : $utils.formatDateTime(product.createdAt) }}
                                </p>
                              </div>

                              <ul class="list-unstyled ps-0 mb-0 mt-3" v-if="product.features && product.features.length > 0">
                                <li v-for="(feature, index) in product.features.slice(0, 4)" :key="index">
                                  <p class="text-muted mb-1 text-truncate">
                                    <i :class="`mdi mdi-circle-medium align-middle me-1 text-${company.color ? company.color : 'primary'}`"></i>
                                    {{ feature }}
                                  </p>
                                </li>
                              </ul>

                              <h4 v-else>
                                {{ product.desc }}
                              </h4>

                              <h3 class="mb-0 mt-4">
                                <span class="text-muted me-2" v-if="product.promoPrice">
                                  <del class="font-size-22 fw-normal">
                                    {{ $utils.formatMoney(product.price) }}
                                  </del>
                                </span>
                                <b>{{ product.promoPrice ? $utils.formatMoney(product.promoPrice) : $utils.formatMoney(product.price) }}</b>
                              </h3>

                              <div :class="`mt-4 d-flex justify-content-${product.link ? 'between' : 'end'}`">
                                <img 
                                  v-if="product.link" 
                                  :src="getPlatformImageById(product.idPlatform)"
                                  alt="image-platform"
                                  height="25"
                                  width="105"
                                />
                                <button
                                  type="button"
                                  :class="`btn btn-rounded waves-effect waves-light btn-${company.color ? company.color : 'primary'}`"
                                  @click="product.link ? openLink(product.urlLink) : addToCart(product)"
                                >
                                  <i class="bx bx-cart me-2 font-size-15 align-middle"></i>
                                  {{ product.link ? "Ir para Oferta" : "Adicionar ao Carrinho" }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { mapActions, mapState } from "vuex";
import skeletonCard from "../../../components/effects/skeletonCard.vue";

export default {
  data() {
    return {
      slug: this.$route.params.slug,
      user: this.$auth.getLoggedUser(),
      company: JSON.parse(localStorage.getItem("company")),
      products: [],
      productsFiltered: [],
      platforms: [],
      filter: "",
    };
  },
  components: {
    Layout,
    skeletonCard,
  },
  async created() {
    this.company = await this.$utils.getCompanyBySlug(this.slug);

    if (this.company) {
      try {
        const res = await this.$api.get("platform");
        this.platforms = res.data;
      } catch (error) {
        console.log(error);
      }

      const products = await this.$utils.getByCompany(
        "product",
        this.company._id
      );
      if (products) {
        this.products = products;
        if (this.currentCategory) {
          this.productsFiltered = this.products.filter((product) => {
            return product.active && product.idCategory == this.currentCategory;
          });
        } else {
          this.productsFiltered = this.products.filter((product) => {
            return product.active;
          });
        }

        this.orderByDateDesc();
      }
    }
  },
  mounted() {},
  watch: {
    currentCategory(newCategory) {
      this.filterCategory(newCategory);
    },
  },
  computed: {
    ...mapState([
      "currentCategory",
      "currentCategoryName",
      "isLoading",
      "isAuthenticated",
    ]),
  },
  methods: {
    ...mapActions(["setCurrentCategory"]),
    addToCart(product) {
      this.$store.dispatch("addToCart", product);
      console.log(this.$store.state.cart);
    },
    openLink(url) {
      window.open(url, "_blank");
    },
    getPlatformImageById(idPlatform) {
        const platform = this.platforms.find((el) => el._id == idPlatform);
        return platform.imageUrl;
    },
    zoomIn(event) {
      event.target.style.transform = "scale(1.1)";
    },
    zoomOut(event) {
      event.target.style.transform = "scale(1)";
    },
    orderByDateDesc() {
      this.productsFiltered.sort((a, b) => {
        const dateA = new Date(a.updatedAt ? a.updatedAt : a.createdAt);
        const dateB = new Date(b.updatedAt ? b.updatedAt : b.createdAt);

        if (dateA > dateB) {
          return -1;
        } else if (dateA < dateB) {
          return 1;
        } else {
          return 0;
        }
      });
    },
    orderByHighlight() {
      this.productsFiltered.sort((a, b) => {
        if (a.highlight && !b.highlight) {
          return -1;
        } else if (!a.highlight && b.highlight) {
          return 1;
        } else {
          return 0;
        }
      });
    },
    filterCategory(category = this.currentCategory) {
      if (!category) {
        this.productsFiltered = this.products.filter((product) => {
          return product.active;
        });
        return;
      }
      this.productsFiltered = this.products.filter((product) => {
        return product.idCategory === category && product.active;
      });
    },
    filterByTitle(title) {
      if (!title && !this.currentCategory) {
        this.productsFiltered = this.products.filter((product) => {
          return product.active;
        });
        return;
      }

      if (!title && this.currentCategory) {
        this.productsFiltered = this.products.filter((product) => {
          return product.idCategory === this.currentCategory && product.active;
        });
        return;
      }

      if (title && this.currentCategory) {
        this.productsFiltered = this.products.filter((product) => {
          return (
            product.title.toLowerCase().includes(title.toLowerCase()) &&
            product.idCategory === this.currentCategory &&
            product.active
          );
        });
        return;
      }

      if (title && !this.currentCategory) {
        this.productsFiltered = this.products.filter((product) => {
          return (
            product.title.toLowerCase().includes(title.toLowerCase()) &&
            product.active
          );
        });
        return;
      }
    },
  },
};
</script>

<style scoped>
.text-container {
  /*max-height: 3.6em; /* Duas linhas de altura (cada linha aproximadamente 1.8em) */
  overflow: hidden;
}

.text-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /*-webkit-line-clamp: 2; /* Máximo de 2 linhas */
  overflow: hidden;
  text-overflow: ellipsis; /* Adiciona reticências no final do texto */
}

.product-image {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease; /* Adiciona uma transição suave para o efeito de zoom */
}
.product-image:hover {
  transform: scale(
    1.1
  ); /* Aplica um aumento de escala ao passar o mouse sobre a imagem */
}
</style>
