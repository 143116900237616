<template>
  <div class="col-xl-6">
    <div class="card">
      <div class="card-body">
        <div class="mb-2">
          <span class="skeleton-box w-100 mt-1 mx-1"></span>
        </div>
        <div class="row align-items-center">
          <div class="col-md-5 text-center">
            <span class="skeleton-box w-100 mt-1" style="height: 200px"></span>
          </div>
          <div class="col-md-7">
            <div class="mt-3 mt-lg-0">
              <span class="skeleton-box w-100 mt-1"></span>
              <span class="skeleton-box w-100 mt-1"></span>
              <span class="skeleton-box w-100 mt-1"></span>
              <span class="skeleton-box w-100 mt-1"></span>
              <span class="skeleton-box w-100 mt-1"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-6">
    <div class="card">
      <div class="card-body">
        <div class="mb-2">
          <span class="skeleton-box w-100 mt-1 mx-1"></span>
        </div>
        <div class="row align-items-center">
          <div class="col-md-5 text-center">
            <span class="skeleton-box w-100 mt-1" style="height: 200px"></span>
          </div>
          <div class="col-md-7">
            <div class="mt-3 mt-lg-0">
              <span class="skeleton-box w-100 mt-1"></span>
              <span class="skeleton-box w-100 mt-1"></span>
              <span class="skeleton-box w-100 mt-1"></span>
              <span class="skeleton-box w-100 mt-1"></span>
              <span class="skeleton-box w-100 mt-1"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-6">
    <div class="card">
      <div class="card-body">
        <div class="mb-2">
          <span class="skeleton-box w-100 mt-1 mx-1"></span>
        </div>
        <div class="row align-items-center">
          <div class="col-md-5 text-center">
            <span class="skeleton-box w-100 mt-1" style="height: 200px"></span>
          </div>
          <div class="col-md-7">
            <div class="mt-3 mt-lg-0">
              <span class="skeleton-box w-100 mt-1"></span>
              <span class="skeleton-box w-100 mt-1"></span>
              <span class="skeleton-box w-100 mt-1"></span>
              <span class="skeleton-box w-100 mt-1"></span>
              <span class="skeleton-box w-100 mt-1"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-6">
    <div class="card">
      <div class="card-body">
        <div class="mb-2">
          <span class="skeleton-box w-100 mt-1 mx-1"></span>
        </div>
        <div class="row align-items-center">
          <div class="col-md-5 text-center">
            <span class="skeleton-box w-100 mt-1" style="height: 200px"></span>
          </div>
          <div class="col-md-7">
            <div class="mt-3 mt-lg-0">
              <span class="skeleton-box w-100 mt-1"></span>
              <span class="skeleton-box w-100 mt-1"></span>
              <span class="skeleton-box w-100 mt-1"></span>
              <span class="skeleton-box w-100 mt-1"></span>
              <span class="skeleton-box w-100 mt-1"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
